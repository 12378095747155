<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline"
              >{{ $t("FORM_INPUT_NAMES.contact_form_types") }}
            </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-btn v-if="!editableItem" @click="handleAddEditableItem">
          {{ $t("FORMS.new") }}
        </v-btn>
        <v-data-table
          :headers="headers"
          :items="editableList"
          @click:row="handleClickItem"
        >
          <template v-slot:item.hu="{ item }">
            <v-text-field
              v-if="editableItem && editableItem.id == item.id"
              v-model="item.translations.hu.name"
            ></v-text-field>
            <span v-else>
              {{ item.translations.hu.name }}
            </span>
          </template>
          <template v-slot:item.en="{ item }">
            <v-text-field
              v-if="editableItem && editableItem.id == item.id"
              v-model="item.translations.en.name"
            ></v-text-field>
            <span v-else>
              {{ item.translations.en.name }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              v-if="editableItem && editableItem.id == item.id"
              fab
              x-small
              @click.stop="saveEditableItem"
            >
              <v-icon color="green" small
                >mdi-checkbox-marked-circle-outline</v-icon
              >
            </v-btn>

            <v-btn
              v-if="editableItem && editableItem.id == item.id"
              fab
              x-small
              class="ml-2"
              @click.stop="resetEditableItem"
            >
              <v-icon color="red" small>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";

export default {
  name: "ContactFormTypeModalForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],

      search: "",

      editableList: [],

      editableItem: null,
    };
  },
  computed: {
    headers() {
      let headers = [
        { text: "id", value: "id" },
        { text: "hu", value: "hu" },
        { text: "en", value: "en" },
        { text: "", value: "action" },
      ];
      return headers;
    },
  },
  watch: {},
  methods: {
    handleCloseModalForm() {
      this.$emit("handleCloseModalForm");
    },

    setAllTranslations(translations, fieldName) {
      this.languages.forEach((element) => {
        if (!translations[element.lang]) {
          translations[element.lang] = {};
          translations[element.lang][fieldName] = "";
        }
      });
      return translations;
    },

    handleClickItem(item) {
      this.editableItem = Object.assign({}, item);
    },

    saveEditableItem() {
      if (this.editableItem.id) {
        ApiService.put(
          this.modalData.apiURL + this.editableItem.id,
          this.editableItem
        ).then(() => {
          this.fetchModel();
        });
      } else {
        ApiService.post(this.modalData.apiURL, this.editableItem).then(
          ({ data }) => {
            console.log(data);
            this.fetchModel();
          }
        );
      }
      this.resetEditableItem();
    },

    resetEditableItem() {
      this.editableItem = null;
      this.fetchModel();
    },

    handleAddEditableItem() {
      this.editableItem = {
        contact_form_data: 0,
        translations: {
          en: { name: "" },
          hu: { name: "" },
        },
      };

      this.editableList.unshift(this.editableItem);
    },

    fetchModel() {
      ApiService.get(this.modalData.apiURL).then(({ data }) => {
        this.editableList = data.map((item) => {
          item["translations"] = this.setAllTranslations(
            item["translations"],
            "name"
          );
          return item;
        });
      });
    },
  },

  mounted() {
    this.loader = false;

    this.fetchModel();
  },
};
</script>
